<template>
	<v-container fill-height class="fluid">
		<div class="d-flex flex-column justify-center align-center px-10">
			<!-- <v-btn @click="loadProjectFolders()">Load Project</v-btn>
		<v-btn @click="loadCaller()">Load Folder</v-btn>
		<v-text-field v-model="projectId" label="Project"></v-text-field>
		<v-text-field v-model="folderId" label="Folder"></v-text-field> -->
			<v-row style="width: 100%" v-if="1 == 2">
				<v-col cols="4">
					<div class="">
						<v-row
							v-for="(item, index) in folderContents"
							:key="index"
						>
							<v-col cols="12">
								<v-card>
									<v-card-text class="px-6 mx-6">
										<p>{{ JSON.stringify(item) }}</p>
									</v-card-text>
								</v-card>
							</v-col>
						</v-row>
					</div>
				</v-col>
				<v-col cols="8">
					<v-row>
						<v-col cols="6">
							<v-list>
								<v-list-item
									two-line
									v-for="(folder, fIndex) in folders"
									:key="fIndex"
								>
									<v-list-item-content>
										<v-list-item-title>{{
											folder.text
										}}</v-list-item-title>
										<v-list-item-subtitle>{{
											folder.id
										}}</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
							</v-list>
						</v-col>
						<v-col cols="6">
							<v-list>
								<v-list-item
									two-line
									v-for="(item, IIndex) in items"
									:key="IIndex"
								>
									<v-list-item-content>
										<v-list-item-title>{{
											item.text
										}}</v-list-item-title>
										<v-list-item-subtitle>{{
											item.id
										}}</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
							</v-list>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			<v-row style="width: 100%" v-if="1==2">
				<div>Active folders: {{ activeFolders }}</div>
				<folder-tree
					v-model="activeFolders"
					:selectedProject="selectedProject"
					startTargetFolderItemId="urn:adsk.wipprod:fs.folder:co.vK9xWlSaSQyv1ddZseZcJA"
					v-if="selectedProject"
				></folder-tree>
				<v-btn @click="test">TEST</v-btn>
			</v-row>
			<v-row>
				<v-col>
					<TmpNewFolderTree 
						:selectedProject="selectedProject"
						v-if="selectedProject"/>
				</v-col>
			</v-row>
			<v-row v-if="1 == 2">
				<v-col :cols="3">
					<div style="border: solid 1px black">
						<div>folders: {{ folders }}</div>
						<v-treeview
							:items="folders"
							item-key="id"
							item-text="text"
							item-children="children"
							:active.sync="activeNodeIds"
							:open.sync="openNodeIds"
							activatable
						>
						</v-treeview>
					</div>
				</v-col>
				<v-col :cols="4">
					<div>openNodeIds: {{ openNodeIds }}</div>
					<v-btn @click="openNodeIds = [1,3], activeNodeIds=[4]">Open</v-btn>
					<v-btn @click="openNodeIds = []">Close</v-btn>
				</v-col>
				<v-col :cols="4">
					<div>openNodeIds: {{ activeNodeIds }}</div>
					<!-- <v-btn @click="openNodeIds=[1]">Open</v-btn>
					<v-btn @click="openNodeIds=[]">Close</v-btn> -->
				</v-col>
			</v-row>
		</div>
	</v-container>
</template>

<script>
	import { mapActions, mapGetters } from "vuex";
	import FolderTree from "./FolderTree.vue";
	// import FolderService from "../services/folderService";
	import TmpNewFolderTree from "./TmpNewFolderTree.vue";
	export default {
		components: {
			"folder-tree": FolderTree,
			TmpNewFolderTree
		},
		data: () => ({
			// folderContents: null,
			// projectId: null,
			// folderId: null,
			// folders: [],
			// items: [],
			// activeFolders: [],
			// // openFolderIds: [],
			folders: [
				{
					id: 1,
					text: "folder - 1",
					children: [
						{
							id: 3,
							text: "folder - 3",
							children: [
								{ id: 4, text: "folder - 4" }
							]
						},
					],
				},
				{ id: 2, text: "folder - 2" },
			],
			activeNodeIds: [],
			openNodeIds: [],
		}),
		computed: {
			...mapGetters(["selectedProject"]),
		},
		methods: {
			...mapActions(["fetchNodes", "fetchNodesNew"]),
			// loadProjectFolders() {
			// 	console.log("Loading project");
			// 	this.fetchNodes({
			// 		item: {
			// 			id: "https://developer.api.autodesk.com/project/v1/hubs/b.f17a3d36-099d-4d60-aa0f-dab12340fecd/projects/b.89903d26-7ab5-4220-89f5-8d387960f7ac",
			// 			text: "demo",
			// 			// type: "bim360projects",
			// 			versionType: null,
			// 			parentId: null,
			// 		},
			// 		addChildrenToParent: false,
			// 	}).then((itms) => {
			// 		this.folderContents = itms;
			// 	});
			// },
			// async loadCaller() {
			// 	this.folderContents = await this.loadFolderContents(
			// 		this.projectId,
			// 		this.folderId
			// 	);
			// },
			// /**
			//  * Load folder contents, then update folder tree and file list.
			//  */
			// async loadFolderContents(projectId, folderId) {
			// 	try {
			// 		const itemUrlId = `https://developer.api.autodesk.com/data/v1/projects/${projectId}/folders/urn:adsk.wipprod:fs.folder:${folderId}`;
			// 		const type = "folders";
			// 		const folderInfo = {
			// 			id: itemUrlId,
			// 			type: type,
			// 			projectId: projectId,
			// 		};
			// 		let itemList = await this.fetchNodesNew({ item: folderInfo });
			// 		this.folders = [
			// 			...itemList.filter((item) => item.type == "folders"),
			// 		];
			// 		this.items = [
			// 			...itemList.filter((item) => item.type != "folders"),
			// 		];
			// 		//  await this.loadAndSetFileItems();
			// 		console.log(
			// 			JSON.stringify(
			// 				itemList.map((item) => ({
			// 					id: item.id,
			// 					text: item.text,
			// 					type: item.type,
			// 					itemId: item.itemId,
			// 				}))
			// 			)
			// 		);
			// 	} catch (error) {
			// 		console.log(`Error: ${error}`);
			// 	}
			// },
			// async test() {
			// 	try {
			// 		const projectId = "b.9c7dfd01-74f4-4d13-a530-df3c22455378";
			// 		const folderId =
			// 			"urn:adsk.wipprod:fs.folder:co.vK9xWlSaSQyv1ddZseZcJA";
			// 		const folderService = new FolderService();
			// 		const tmpFolders = await folderService.getItemPathFolders(
			// 			projectId,
			// 			folderId
			// 		);
			// 		const tmpfolderTree =
			// 			folderService.folderListToTree(tmpFolders);

			// 		// const tmpFolders = await this.getItemPath();
			// 		this.folders = [...tmpfolderTree];
			// 		this.openFolderIds = tmpfolderTree.map(
			// 			(folder) => folder.itemId
			// 		);
			// 		// @TODO: Set active node
			// 		console.log(JSON.stringify(this.folders[0].children[0]));
			// 		this.activeFolders = [this.folders[0].children[0]];
			// 	} catch (error) {
			// 		// @TODO: Implement error management
			// 		console.log(`ERROR: Calle exited with error.\n${error}`);
			// 	}
			// },
		},
	};
</script>

<style lang="scss" scoped>
</style>